import { defineComponent as _defineComponent } from 'vue'
import { IUserVerificationDTO } from "@/models/User";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import debounce from "lodash/debounce";
import { Field, Form } from "vee-validate";
import { reactive, ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { object, string } from "yup";
import AccountModule from "@/store/modules/Account";
import SearchAutocomplete from "./components/SearchAutocomplete.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CRUDUser',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const schema = object({
  Firstname: string().required().label("First Name"),
  Lastname: string().required().label("Last Name"),
  Email: string().required().email().label("Email"),
  Username: string()
    .required()
    .matches(/^[a-zA-Z0-9_]+$/, "Username incorrect format")
    .strict()
    .trim()
    .label("User Name"),
  Phonenumber: string().required().max(10).label("Phone Number"),
});
const isOpen = ref(false);
const isLoading = ref(false);
const results = ref([]) as any;
const items = ref([]) as any;
const { TenantId }: { TenantId?: string } = route.params;
const errorMessageRefEmail = ref("");
const errorMessageRefUsername = ref("");
const arrowCounter = ref(-1);
const emailInput: any = ref(null);
const dataAccountProfile = computed(
  () => AccountModule.dataAccountProfileGetter
).value.Email.split("@");
items.value = [dataAccountProfile[1]];
const onSubmit = async (values: any, actions: any) => {
  if (errorMessageRefUsername.value || errorMessageRefEmail.value) return;
  const data = { ...values };
  await TenantUserModule.createUser(data);
  if (!TenantUserModule.hasCreateUserErrorMsg) {
    actions.resetForm();
    router.push(``);
  }
};
const onValidateEmailUserName = debounce(async (value, field, meta) => {
  const key = field.name as string;
  const data = {
    [key]: value,
  } as IUserVerificationDTO;
  isOpen.value = true;
  filterResults(value);
  if (meta.valid && value !== "") {
    const messageError = await TenantUserModule.verificationUser(data);
    if (messageError !== "") {
      return (errorMessageRefEmail.value = messageError);
    }
  }
  errorMessageRefEmail.value = "";
}, 100);
const filterResults = (value: string) => {
  if (value.includes("@")) {
    isOpen.value = false;
  }
  results.value = items.value.map((item: any) => {
    return `${value}@${item}`;
  });
};
const onValidateUserName = debounce(async (value, field, meta) => {
  const key = field.name as string;
  const data = {
    [key]: value,
  } as IUserVerificationDTO;
  if (meta.valid && value !== "") {
    const messageError = await TenantUserModule.verificationUser(data);
    if (messageError !== "") {
      return (errorMessageRefUsername.value = messageError);
    }
  }
  errorMessageRefUsername.value = "";
}, 100);
const handleClickOutside = (event: Event) => {
  isOpen.value = false;
};
const customClickInput = (result: any, field: any, meta: any) => {
  isOpen.value = false;
  onValidateEmailUserName(result, field, meta);
};
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  document.addEventListener("click", handleClickOutside);
});

const __returned__ = { route, router, schema, isOpen, isLoading, results, items, TenantId, errorMessageRefEmail, errorMessageRefUsername, arrowCounter, emailInput, dataAccountProfile, onSubmit, onValidateEmailUserName, filterResults, onValidateUserName, handleClickOutside, customClickInput, get IUserVerificationDTO() { return IUserVerificationDTO }, get TenantUserModule() { return TenantUserModule }, get debounce() { return debounce }, get Field() { return Field }, get Form() { return Form }, reactive, ref, onMounted, onUnmounted, computed, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get object() { return object }, get string() { return string }, get AccountModule() { return AccountModule }, SearchAutocomplete }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})