import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "container my-3 2xl:my-5 py-3 px-10 2xl:py-1 2xl:px-20" }
const _hoisted_5 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "autocomplete" }
const _hoisted_8 = {
  key: 0,
  class: "loading"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_13 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: `/user` }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" User Management ")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Create New User ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }, [
        _createElementVNode("h1", {
          class: "text-2xl 2xl:text-4xl font-bold text-cafe-dark",
          "data-aos": "fade-right"
        }, " Create New User ")
      ], -1)),
      _createVNode($setup["Form"], {
        as: "el-form",
        "validation-schema": $setup.schema,
        onSubmit: $setup.onSubmit,
        "label-position": "top"
      }, {
        default: _withCtx(({ isSubmitting, setFieldValue, setFieldError }) => [
          _createElementVNode("div", {
            class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["Field"], { name: "Email" }, {
                    default: _withCtx(({ value, field, errorMessage, meta }) => [
                      _createVNode(_component_el_form_item, {
                        class: _normalizeClass([$setup.errorMessageRefEmail && 'is-error', 'inputfield']),
                        error: errorMessage,
                        label: "Email*"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, {
                            "model-value": value,
                            onInput: (value) => $setup.onValidateEmailUserName(value,
                    field, meta),
                            ref: "emailInput"
                          }), null, 16, ["model-value", "onInput"]),
                          _withDirectives(_createElementVNode("ul", {
                            id: "autocomplete-results",
                            class: _normalizeClass(_ctx.styles['autocomplete-results'])
                          }, [
                            ($setup.isLoading)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_8, " Loading results... "))
                              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.results, (result, i) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: i,
                                    onClick: 
                          () => {
                            setFieldError('Email', '');
                            setFieldValue('Email', result);
                            $setup.customClickInput(result, field, meta);
                          }
                        ,
                                    class: _normalizeClass([
                          { 'is-active': i === $setup.arrowCounter },
                          _ctx.styles['autocomplete-result'],
                        ])
                                  }, _toDisplayString(result), 11, _hoisted_9))
                                }), 128))
                          ], 2), [
                            [_vShow, $setup.isOpen]
                          ]),
                          ($setup.errorMessageRefEmail)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($setup.errorMessageRefEmail), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["class", "error"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createVNode($setup["Field"], { name: "Firstname" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage,
                      label: "First Name*"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "Phonenumber" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage,
                      label: "Phone Number*"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode($setup["Field"], { name: "Username" }, {
                  default: _withCtx(({ value, field, errorMessage, meta }) => [
                    _createVNode(_component_el_form_item, {
                      class: _normalizeClass([$setup.errorMessageRefUsername && 'is-error', 'inputfield']),
                      error: errorMessage,
                      label: "User name*"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, {
                          "model-value": value,
                          onInput: (value) => $setup.onValidateUserName(value, field, meta)
                        }), null, 16, ["model-value", "onInput"]),
                        ($setup.errorMessageRefUsername)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString($setup.errorMessageRefUsername), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["class", "error"])
                  ]),
                  _: 1
                }),
                _createVNode($setup["Field"], { name: "Lastname" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage,
                      label: "Last Name*"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                class: "btn btn--green-primary",
                disabled: isSubmitting,
                submit: ""
              }, " Save ", 8, _hoisted_14)
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["validation-schema"])
    ])
  ]))
}